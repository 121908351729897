<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <b-card>
        <div class="d-flex align-items-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-25"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h3 class="mb-0 ml-2 d-flex justify-content-between align-items-center">
            <!--   @TODO: Replace static Image with S3. -->
            <img
              :style="{width: '50px', height: 'auto'}"
              alt="Company Logo"
              class="mr-25"
              src="https://static-00.iconduck.com/assets.00/google-icon-512x512-yk2xx8br.png"
            >
            <span>{{ startup.title || startup.registered_title }}</span>
          </h3>
        </div>
        <hr>
        <b-table-simple
          bordered
          responsive
        >
          <b-tr>
            <b-td>Registered Name</b-td>
            <b-td>{{ startup.registered_title }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Role</b-td>
            <b-td>
              <div class="d-flex align-items-center justify-content-between">
                <b-badge
                  variant="light-primary"
                  class="text-capitalize font-small-4"
                >
                  {{ startup.role === 'startup' ? 'Network' : startup.role }}
                </b-badge>
                <b-dropdown
                  size="sm"
                  toggle-class="text-decoration-none"
                  variant="outline-primary"
                  text="Change Role"
                >
                  <b-dropdown-item @click="updateRole('pre-incubatee')">
                    Pre-incubatee
                  </b-dropdown-item>
                  <b-dropdown-item @click="updateRole('incubatee')">
                    Incubatee
                  </b-dropdown-item>
                  <b-dropdown-item @click="updateRole('graduate')">
                    Graduate
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Founders</b-td>
            <b-td>
              <b-badge
                v-for="(founder, index) in startup.startups_founderstables"
                :key="index"
                class="mr-1 cursor-pointer"
                pill
                variant="primary"
                @click="founderDetails = founder; $bvModal.show('founderDetails')"
              >
                {{ founder.name }}
              </b-badge>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Description</b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>Industry</b-td>
            <b-td>{{ startup.industry }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Location</b-td>
            <b-td>{{
              [startup.city, startup.state]
                .filter(e => e)
                .join(', ')
            }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Lifecycle Stage</b-td>
            <b-td>{{ startup.startup_stage }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Website Link</b-td>
            <b-td>
              <b-link
                :href="prependHttps(startup.url)"
                target="_blank"
              >{{ startup.url }}
              </b-link>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>CIN</b-td>
            <b-td>{{ startup.cin }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>DPIIT</b-td>
            <b-td>{{ startup.dpiit }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Official Email</b-td>
            <b-td>{{ startup.official_email }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Official Phone Number</b-td>
            <b-td>{{ startup.official_phone }}</b-td>
          </b-tr>
        </b-table-simple>
      </b-card>
    </b-overlay>
    <b-modal
      id="founderDetails"
      hide-footer
      title="Founder Details"
    >
      <b-table-simple
        bordered
        responsive
      >
        <b-tr>
          <b-td>Name</b-td>
          <b-td>{{ founderDetails.name }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Designation</b-td>
          <b-td>{{ founderDetails.phone }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Phone</b-td>
          <b-td>{{ founderDetails.designation }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Email</b-td>
          <b-td>{{ founderDetails.email }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>DIN</b-td>
          <b-td>{{ founderDetails.din_number }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Equity Holding</b-td>
          <b-td>{{ founderDetails.equity_holding }}%</b-td>
        </b-tr>
      </b-table-simple>

    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BCard, BDropdown, BDropdownItem, BLink, BTableSimple, BTd, BTr,
} from 'bootstrap-vue'

import gql from 'graphql-tag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
    BCard,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      startup: {},
      founderDetails: {},
      mutationLoading: false,
    }
  },
  methods: {

    // Graphql mutation to update role in users_associationtable
    updateRole(role) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRole($id: Int!, $role: String!) {
            update_users_associationtable_by_pk(pk_columns: {id: $id}, _set: {role: $role}) {
              id
              role
            }
          }
        `,
        variables: {
          id: this.$route.params.id,
          role,
        },
        update: () => {
          this.mutationLoading = false
          this.startup.role = role
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Role changed to ${role}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        },
      })
        .catch(() => {
          this.mutationLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  apollo: {
    startup: {
      query() {
        return gql`
           query about_startup {
           users_associationtable_by_pk(id: ${this.$route.params.id} ) {
             role
             users_organizationtable {
                title
                registered_title
                startups_founderstables{
                  name
                  phone
                  designation
                  email
                  din_number
                  equity_holding
                }
                industry
                city
                state
                startup_stage
                url
                cin
                dpiit
                official_email
                official_phone
              }
           }

        }`
      },
      update: data => ({ role: data.users_associationtable_by_pk.role, ...data.users_associationtable_by_pk.users_organizationtable }),
    },
  },
}
</script>
